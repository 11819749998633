/**
 * Calendar Type
 */
export enum CalendarType {
  LEGACY = 'legacy',
  BIG_CALENDAR = 'bigCalendar',
}

/**
 * View
 */
export enum View {
  DAY = 'day',
  WEEK = 'week',
  WORK_WEEK = 'work_week',
  MONTH = 'month',
}
